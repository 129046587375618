import React, { useState } from "react"
import { Link, NavLink, useLocation  } from 'react-router-dom'
import './../includes/styles_burger.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

const Header = (props) => {

    const [navbarOpen, setNavbarOpen] = useState(false)
    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }
    const closeMenu = () => {
        setNavbarOpen(false)
    }


    
  const location = useLocation();
  const getCurrentTitle = () => {
    switch (location.pathname) {
      case "/":
      default:
        return "Calcul de la clairance de la créatinine";
      case "/Oncologie":
        return "Optimisation des doses de Produit De Contraste";
      case "/Vasculaire":
        return "Optimisation des doses de Produit De Contraste";
      case "/Clairance_CKP":
        return "Calcul de la clairance de la créatinine";
    }
  };

    return (
        <header>
            <Link className="menu_titre" to="/">{getCurrentTitle()}</Link>
            <nav className="navBar">
                <button className="burger" onClick={handleToggle}>
                    {navbarOpen ?
                        <FontAwesomeIcon className="gomme f20" icon={faXmark} />
                        :
                        <FontAwesomeIcon className="gomme f20" icon={faBars} />
                    }
                </button>
                <div className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                    <NavLink
                        className="nav active-link"
                        to="/"
                        onClick={() => closeMenu()}
                    >Calcul de la clairance
                    </NavLink>
                    <NavLink
                        className="nav active-link"
                        to="/Oncologie"
                        onClick={() => closeMenu()}
                    >Protocoles d'injection en Oncologie
                    </NavLink>
                    <NavLink
                        className="nav active-link"
                        to="/Vasculaire"
                        onClick={() => closeMenu()}
                    >Protocoles d'injection en Vasculaire
                    </NavLink>

                </div>
            </nav>
        </header>
    )
}
export default Header