import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
//import { styles } from './styles'

const OptiClairance = (props) => {
  let meta_title = "clairance-creatinine.fr - L'appli OptiDose";
  let meta_cannonical = "https://clairance-creatinine.fr/OptiDose";
  let meta_description = "OptiDose - l'appli ";

  return (
    <div className="">
      <Helmet>
        <title>{meta_title}</title>
        <link rel="canonical" href={meta_cannonical} />
        <meta name="description" content={meta_description} />
      </Helmet>
      <div className="flex1">
        <div className="span850 clairance_mobi_d">
          <h2>Clairance l'appli</h2>
          <h3>Le calculateur est maintenant disponible en application mobile</h3>
          <div>
            <Link className="lien" to="https://play.google.com/store/">
              <img
                src="https://www.formation-continue-imagerie.fr/images/android.jpg"
                width="200"
                alt="Télécharger l'appli sur Play Store"
              />
            </Link>
          </div>
          <div>
            <Link className="lien" to="https://www.apple.com/fr/app-store/">
              <img
                src="https://www.formation-continue-imagerie.fr/images/ios.jpg"
                width="200"
                alt="Télécharger l'appli sur IOS"
              />
            </Link>
          </div>

          <p>
            L'utilisation est aussi simple que sur le site web
          </p>
        </div>
      </div>
    </div>
  );
};
export default OptiClairance;
