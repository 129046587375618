import axios from "axios";
import { config } from "../config";


//console.log("Connexion à ", config.api_url+'/pubDroite')
// OK - Sélectionne la pub de droite qui est en ligne
export const pubDroite = (data) => {
  return axios.get(`${config.api_url}/pubDroite`, data)
    .then((res) => {
      //console.log("zzz", res.data.pub_d[0])
      return res.data.pub_d[0];
    })
    .catch((err) => err.response.data);
};

// OK - Sélectionne la pub de droite PDC qui est en ligne
export const pubDroitePDC = () => {
	//console.log("Connexion à ")
  return axios.get(`${config.api_url}/pubDroitePDC`)
    .then((res) => {
      //console.log("zzz", res.data.pub_d[0])
      return res.data.pub_d[0];
    })
    .catch((err) => err.response.data);
};

// OK - 
export const insert_stat_pub = (data) => {
  //console.log("Dans API insert_stat_pub", data)
  return axios.post(`${config.api_url}/insert_pub`, data)
    .then((res) => {
      console.log("zzz", res.status)
      return res.status;
    })
    .catch((err) => err.response.data);
};

// OK - Sélectionne le nombre de vues du jour
export const compte_pubDroite = (pub_id) => {
  //console.log("Dans API PUBS compte_pubDroite pour la pub avec l'ID : ", pub_id)
  return axios.get(config.api_url + "/compte_pubDroite/" + pub_id)
    .then((res) => {
      //console.log("zzz", res.data.pub_d[0])
      return res.data.pub_d[0];
    })
    .catch((err) => err.response.data);
};

// OK - ajoute +1 dans vus_total
export const update_stat_pubDejaClique = (update1) => {
  //console.log("Dans API update_stat_pubDejaClique", update1.id_pub, "vus_total", update1)
  return axios.post(config.api_url + "/update_pubDejaClique/" + update1.id_pub, update1)
    .then((res) => {
      return res.status;
    })
    .catch((err) => err.response.data);
};

export const update_stat_pub2 = (update2) => {
  //console.log("Dans API update_stat_pub2", update2.id_pub, "vus", update2.vus)
  return axios.post(config.api_url + "/update_pub2/" + update2.id_pub, update2)
    .then((res) => {
      return res.status;
    })
    .catch((err) => err.response.data);
};


/* -------------------ADMIN-----------------------*/

// OK - Sélectionne toutes les pubs
export const allPub = () => {
  return axios.get(`${config.api_url}/allPub`)
    .then((res) => {
      //console.log("zzz", res.data.pub_d[0])
      return res.data.allPub;
    })
    .catch((err) => err.response.data);
};
