import axios from "axios";
import { config } from "../config";
// recupérer le token avec getItem
//const token = window.localStorage.getItem('VN_token')



export const getAllStatsClairance = (data) => {
	console.log("Dans API")
  return axios.get(`${config.api_url}/zzz/clairance/all`, data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};



// ajout d'une recherche clairance CKP
export const addClairanceCKP = async (datas)=>{
  //console.log("addClairanceCKP : ", datas)
  return axios.post(config.api_url+'/addClairanceCKP', datas )
    .then((response)=>{
      return response.data;
    })
    .catch((err)=>{
        return err
    })
}

// ajout d'une recherche clairance MDRD
export const addClairanceMDRD = async (datas)=>{
  //console.log("addClairanceMDRD : ", datas)
  return axios.post(config.api_url+'/addClairanceMDRD', datas )
    .then((response)=>{
      return response.data;
    })
    .catch((err)=>{
        return err
    })
}

// ajout d'une recherche PDC onco
export const addPDConco = async (datas)=>{
  //console.log("addPDConco : ", datas)
  return axios.post(config.api_url+'/addPDConco', datas )
    .then((response)=>{
      return response.data;
    })
    .catch((err)=>{
        return err
    })
}

// ajout d'une recherche PDC vasculaire
export const addPDCvasc = async (datas)=>{
  //console.log("addPDCvasc : ", datas)
  return axios.post(config.api_url+'/addPDCvasc', datas )
    .then((response)=>{
      return response.data;
    })
    .catch((err)=>{
        return err
    })
}
