import React from 'react'
import { Link } from "react-router-dom";
import './../styles.css';

const Error = () => {
    return (
        <div classname="vertical_center">
            <h1><br />Cette page n'existe pas<br /></h1>
            <h2><br /><Link to="/" >Retourner à l'accueil</Link><br /></h2>
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}
export default Error