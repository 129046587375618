import { useEffect } from "react";
import {addClairanceCKP} from '../../api/stats'

export function useAutoSaveCKP(storageId, data, delay = 2000) {
    useEffect(() => {
        const timeoutId = setTimeout(() => {

            if (data.ckp !== null) {
                console.log("Sauvegarde des données CKD", data.ckp);
                //localStorage.setItem(storageId, JSON.stringify(data));
                addClairanceCKP(data)
            }
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [delay, data, storageId]);
}


export default useAutoSaveCKP