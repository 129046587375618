import { useEffect } from "react";
import {addClairanceMDRD} from '../../api/stats'

export function useAutoSaveMDRD(storageId, data, delay = 2000) {
    useEffect(() => {
        const timeoutId = setTimeout(() => {

            if (data.clairance !== null) {
                console.log("Sauvegarde des données MDRD", data.clairance);
                //localStorage.setItem(storageId, JSON.stringify(data));
                addClairanceMDRD(data)
            }
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [delay, data, storageId]);
}


export default useAutoSaveMDRD