import React, { useState, useEffect } from "react";
import ReactSlider from "react-slider";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./styles.css";

import Interpretation from "./includes/Interpretation";
import Actu from "./includes/Actu";
import useAutoSaveCKP from "./BDD/BDD_ckp";
import PubDroite from "./pubs/pub_droite";

const Clairance_CKP = () => {
  let pageTitle = "Calcul de la clairance de la créatinine selon CKD-EPI";
  let meta_cannonical = "https://clairance-creatinine.fr";
  let meta_description = "La clairance est indispensable avant toute injection de produit de contraste au scanner. Le calcul se fait selon la formule CKD-EPI";

  var anneeActuelle = new Date().getFullYear();
  const [annee, setAnnee] = useState("");
  const [age, setAge] = useState(0);
  const [creatinine, setCreatinine] = useState("");
  const [unite, setUnite] = useState("µmol/L");
  const [switchUnite, setSwitchUnite] = useState("mg/L");
  const [convertUnite, setConvertUnite] = useState(1);
  const [resultats, setResultats] = useState(null);
  const [sexe, setSexe] = useState("");
  const [afro, setAfro] = useState(1);
  const [methodeCalcul, setMethodeCalcul] = useState("");
  const [limitAge, setLimitAge] = useState(null);
  const [limitCreat, setLimitCreat] = useState(null);
  const [couleur, setCouleur] = useState("");
  const [fonction, setFonction] = useState("");

  useEffect(() => {
    document.title = "Calcul de la clairance de la créatinine selon CKD-EPI";
  }, []);

  const Reset = () => {
    setResultats(null);
    setCreatinine("");
    setAge("");
    setAnnee("");
    setSexe("");
    setMethodeCalcul("");
  };

  const donnees = {
    annee: annee,
    age: age,
    sexe: sexe,
    afro: afro,
    creatinine: creatinine,
    ckp: resultats,
  };

  // C'est ici pour sauvegarder les résultats dans le BDD
  useAutoSaveCKP("donnees", donnees);

  function calculAge() {
    if (annee !== "") {
      var age = anneeActuelle - annee;
      setAge(age);
    }
  }

  const calc_ckp = () => {
    setResultats(null);

    if (unite === "mg/L") {
      setConvertUnite(0.11312);
    } else {
      setConvertUnite(1);
    }

    let xckp;
    if (sexe === "Homme") {
      xckp =
        141 *
        Math.pow(Math.min(creatinine / convertUnite / 79.6, 1), -0.411) *
        Math.pow(Math.max(creatinine / convertUnite / 79.6, 1), -1.209) *
        Math.pow(0.993, age) *
        afro;
    }
    if (sexe === "Femme") {
      xckp =
        141 *
        Math.pow(Math.min(creatinine / convertUnite / 61.9, 1), -0.329) *
        Math.pow(Math.max(creatinine / convertUnite / 61.9, 1), -1.209) *
        Math.pow(0.993, age) *
        1.018 *
        afro;
    }
    setResultats(xckp.toFixed(1));
  };

  useEffect(() => {
    calculAge();

    if (creatinine === 250) {
      setLimitCreat(true);
    } else {
      setLimitCreat("");
    }

    if (annee === 1910) {
      setAge("");
      setAnnee("");
      setMethodeCalcul("");
    } else if (age > 0 && age < 130) {
      // calcul selon Cockroft
      setMethodeCalcul("CKD-EPI");
      setResultats(null);
      if (creatinine !== "" && sexe !== "") {
        console.log("zzzz")
        calc_ckp();
      }
    } else {
      setMethodeCalcul("");
      setResultats(null);
    }

    if (age > 130) {
      setLimitAge(true);
      setResultats(null);
    } else {
      setLimitAge("");
    }

    if (resultats > 0 && resultats < 30) {
      setCouleur("noir");
      setFonction("Stade 4 : Insuffisance rénale sévère");
    }
    if (resultats >= 30 && resultats < 45) {
      setCouleur("rouge");
      setFonction("Stade 3B : Insuffisance rénale modérée");
    }
    if (resultats >= 45 && resultats < 60) {
      setCouleur("rouge");
      setFonction("Stade 3A : Insuffisance rénale modérée");
    }
    if (resultats >= 60 && resultats < 90) {
      setCouleur("orange");
      setFonction("Stade 2 : Insuffisance rénale légère");
    }
    if (resultats >= 90 && resultats <= 2000) {
      setCouleur("vert");
      setFonction("Valeurs normales");
    }
  }, [calculAge]);


  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={meta_cannonical} />
        <meta name="description" content={meta_description} />
      </Helmet>
      <div className="flex1">
        <div className="resultats_clairance">
          {resultats !== null ? (
            <div className={`ok ${couleur}`}>
              <p className="examen clairance">
                <span className="bold"> Clairance de la créatinine</span> selon{" "}
                <span className="bold">{methodeCalcul} </span>{" "}
              </p>
              <p className="injection">{resultats} *</p>
              <p className="examen clairance">
                <span className="bold">{fonction} </span>
              </p>
            </div>
          ) : (
            <div className="attente">
              <p className="attente_texte">
                Les résultats s'afficheront içi quand les critères
                indispensables seront renseignés
              </p>
            </div>
          )}
        </div>
        <div className="main">
          <div className="gauche">
            <h1 className="titreClairance">
              Calcul de la clairance de la créatinine{" "}
            </h1>
            <h2 className="titreClairance">
              {" "}
              selon CKD-EPI{" "}
              <FontAwesomeIcon
                className="gomme"
                icon={faEraser}
                onClick={() => {
                  Reset();
                }}
              />
            </h2>

            <div className="row">
              <div className="zzzz">
                <div className="InputAddOn">
                  <span className="InputAddOn-item">Année naissance</span>
                  <input
                    className="InputAddOn-field"
                    placeholder="année"
                    value={annee}
                    onChange={(annee) => {
                      setAnnee(annee.target.value);
                    }}
                  />
                </div>
                <ReactSlider
                  className="customSlider"
                  thumbClassName="customSlider-thumb"
                  trackClassName="customSlider-track"
                  value={Number(annee)}
                  min={1910}
                  max={anneeActuelle}
                  defaultValue={0}
                  onChange={(value) => setAnnee(value)}
                />
              </div>

              <div className="zzzz">
                <div className="InputAddOn">
                  <span className="InputAddOn-item">Age</span>
                  <input
                    className="InputAddOn-field"
                    placeholder="ans"
                    value={age}
                    onChange={(age) => {
                      setAge(age.target.value);
                    }}
                  />
                  <span className="InputAddOn-item">ans</span>
                </div>
                {limitAge !== "" && (
                  <p className="errorPatient">Age incorrect</p>
                )}
              </div>
            </div>

            <div className="row">
              <div>
                <h2 className="titre">Sexe</h2>
                <div className="radio2">
                  <label
                    className={`label_group_row btn_clairance ${
                      sexe === "Homme" ? "checked" : ""
                    }`}
                  >
                    Homme
                    <input
                      className="radio"
                      name="sexe"
                      label="Homme"
                      type="radio"
                      defaultChecked={sexe === "Homme"}
                      onClick={() => setSexe("Homme")}
                    />
                  </label>
                  <label
                    className={`label_group_row btn_clairance ${
                      sexe === "Femme" ? "checked" : ""
                    }`}
                  >
                    Femme
                    <input
                      className="radio"
                      name="sexe"
                      label="Femme"
                      type="radio"
                      defaultChecked={sexe === "Femme"}
                      onClick={() => setSexe("Femme")}
                    />
                  </label>
                </div>
              </div>
              <div>
                <h2 className="titre">Patient à peau noire</h2>
                <div className="radio2">
                  <label
                    className={`label_group_row btn_clairance ${
                      afro === 1.159 ? "checked" : ""
                    }`}
                  >
                    Oui
                    <input
                      className="radio"
                      name="afro"
                      label="Oui"
                      type="radio"
                      defaultChecked={afro === 1.159}
                      onClick={() => setAfro(1.159)}
                    />
                  </label>
                  <label
                    className={`label_group_row btn_clairance ${
                      afro === 1 ? "checked" : ""
                    }`}
                  >
                    Non
                    <input
                      className="radio"
                      name="afro"
                      label="Non"
                      type="radio"
                      defaultChecked={afro === 1}
                      onClick={() => setAfro(1)}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="div_clairance">
              <p>
                Taux de créatinine en{" "}
                <u>
                  <b>{unite}</b>
                </u>
                <span
                  className="link"
                  onClick={() => {
                    setUnite(switchUnite);
                    setSwitchUnite(unite);
                    setCreatinine("");
                  }}
                >
                  {" "}
                  passer en {switchUnite}
                  <sup className="crouge"> * nouveau !</sup>
                </span>
              </p>
              <div className="zzzz">
                <div className="InputAddOn">
                  <span className="InputAddOn-item">Taux de créatinine</span>
                  <input
                    className="InputAddOn-field"
                    placeholder={unite}
                    value={creatinine}
                    onChange={(creatinine) => {
                      setCreatinine(creatinine.target.value);
                    }}
                  />
                  <span className="InputAddOn-item">{unite}</span>
                </div>
                <ReactSlider
                  className="customSlider"
                  thumbClassName="customSlider-thumb"
                  trackClassName="customSlider-track"
                  value={Number(creatinine)}
                  min={2}
                  max={250}
                  defaultValue={0}
                  onChange={(value) => setCreatinine(value)}
                />
              </div>
              {switchUnite !== "mg/L" && (
                <p className="errorPatient">
                  Attention vous êtes passé en {switchUnite} pour le taux de
                  créatinine
                </p>
              )}
            </div>

            {limitCreat !== "" && (
              <p className="errorPatient">
                Rentrez manuellement le taux de créatinine si supérieur
              </p>
            )}
          </div>
          <div className="droite">
            <PubDroite />
          </div>
        </div>
      </div>
      <div className="well centre noir">
        <Link className="lien" to="/Clairance">
          <h3>
            Revenir vers l'ancien calculateur selon Cockroft & Gault et MDRD
          </h3>
        </Link>
      </div>
      <div className="container">
        <Actu />
      </div>
      <div className="flex1">
        <Interpretation />
      </div>
    </div>
  );
};

export default Clairance_CKP;
