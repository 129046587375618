import React from 'react'
import './styles_actu.css';

const Interpreation = () => {
    return (
        <div>
            <h2 className="titreClairance">L'actualité pour les manips radio</h2>
            <hr />

            <div className=" ">
                <div className="container">
                    <h2 className="">
                        <a href="https://www.formation-continue-imagerie.fr/">
                            <img 
                                className="img-responsive" 
                                src="https://v1.clairance-creatinine.fr/images/affiche_formations.jpg" 
                                alt="FCMR" />
                        </a>
                    </h2>
{/*}
                    <div className="row">
                        <div className="column">
                            <h4 className="radioanatomie"><a href="">Formations</a></h4>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=IRM" className="btn btn-primary btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> IRM</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=Scanner" className="btn btn-primary btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Scanner</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=Radiologie" className="btn btn-primary btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Radiologie</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=Radiologie interventionnelle" className="btn btn-primary btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Radiologie interventionnelle</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=Echographie" className="btn btn-primary btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Echographie</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=Mammographie" className="btn btn-primary btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Mammographie</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=Médecine nucléaire" className="btn btn-success btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Médecine nucléaire</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri.php?region=Radiothérapie" className="btn btn-warning btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Radiothérapie</a>
                        </div>

                        <div className="column">
                            <h4 className="radioanatomie"><a href="#">Formations E-Learning</a></h4>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri_elearning.php?base=elearning" className="btn btn-danger btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Formations en e-learning</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri_site.php?base=site" className="btn btn-info btn-block"><FontAwesomeIcon className="blanc" icon={faSearchPlus} /> Formations sur site</a>
                            <a href="https://www.formation-continue-imagerie.fr/002_formations/000_formations_manipulateurs_tri_elearning.php?base=elearning"><button id="singlebutton" className="singlebutton btn btn-warning btn-block">Radioprotection</button></a>
                        </div>
                    </div>
    {*/}                    
                </div>
            </div>

            <div className="AFPPE fblanc">
                <div className="row">
                    <h2 className="centered"><a href="https://new.afppe.com/"><img className="img-responsive" src="https://v1.clairance-creatinine.fr/images/afppe.png" alt="AFPPE" /></a></h2>
                </div>
            </div>


            <div className="">
                <div className="container">
                    <h2 className="centered radioanatomie"><a className="brand" href="https://www.radioanatomie.com/"><img alt="radioanatomie.com" className="lazy" src="https://www.radioanatomie.com/icones/crane.png" />
                        <span className="color-highlight"> R</span>adioanatomie<span className="color-highlight">.com</span>
                    </a>
                    </h2>
                    <div className="row">
                        <div>
                            <h4 className="centered radioanatomie"><a href="https://www.radioanatomie.com/60_coeur_tdm/">Les plans de coupes en IRM</a></h4>
                            <a href="https://www.radioanatomie.com/61_coeur_irm/"><img className="img_radioanatomie" src="https://www.radioanatomie.com/61_coeur_irm/99_images/couleur/c4-chambre-de-chasse-du-ventricule-gauche.jpg" alt="radioanatomie" /></a>
                        </div>
                        <div>
                            <h4 className="centered radioanatomie"><a href="https://www.radioanatomie.com/60_coeur_tdm/">Le coeur sur coupes TDM</a></h4>
                            <a href="https://www.radioanatomie.com/60_coeur_tdm/"><img className="img_radioanatomie" src="https://www.radioanatomie.com/60_coeur_tdm/99_images/couleur/3.jpg" alt="radioanatomie" /></a>
                        </div>
                        <div>
                            <h4 className="centered radioanatomie"><a href="https://www.radioanatomie.com/30_irm_sagittale_mediane/coupe_sagittale_mediane_irm.php?vue=2">La coupe sagittale en IRM </a></h4>
                            <a href="https://www.radioanatomie.com/30_irm_sagittale_mediane/coupe_sagittale_mediane_irm.php?vue=2"><img className="img_radioanatomie" src="https://www.radioanatomie.com/29_citernes/99_images/couleur/02.jpg" alt="radioanatomie" /></a>
                        </div>
                    </div>


                </div>
            </div>


            <div className="Promi fblanc" >
                <div className="row">
                    <div className="col-sm-9 ">
                        <h2 className="centered"><a href="https://promi-imagerie.org/"><img className="img-responsive centered lazy titre_centre" src="https://v1.clairance-creatinine.fr/images/promi.jpg" alt="PROMI" /></a></h2>
                        <hr />
                        <div className="row">
                        <div className="col-lg-4">
                            <h4 className="centered"><a href="https://promi-imagerie.org/Pour-apprendre/#les+affiches">Des affiches pour expliquer les techniques d'imagerie</a></h4>
                            <a href="https://promi-imagerie.org/Pour-apprendre/#les+affiches"><img className="img_promi" src="https://v1.clairance-creatinine.fr/images/promi_affiche.jpg" alt="PROMI" /></a>
                        </div>
                        <div className="col-lg-4">
                            <h4 className="centered"><a href="https://promi-imagerie.org/Pour-apprendre/#expos%C3%A9s">Des exposés explicatifs<br />&nbsp;</a><br /></h4><br />
                            <a href="https://promi-imagerie.org/Pour-apprendre/#expos%C3%A9s"><img className="img_promi" src="https://promi-imagerie.org/gallery/Il%20%C3%A9tait%20une%20fois%20IRM%20partie%201%20dia%201-ts1597255315.svg" alt="PROMI" /></a>
                        </div>
                        <div className="col-lg-4">
                            <h4 className="centered"><a href="https://promi-imagerie.org/Pour-apprendre/#quiz">Des quizz pour tester vos connaissances </a></h4>
                            <a href="https://promi-imagerie.org/Pour-apprendre/#quiz"><img className="img_promi" src="https://v1.clairance-creatinine.fr/images/promi_quizz.jpg" alt="PROMI" /></a>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Interpreation
