import React, { useState, useEffect } from 'react'
import moment from "moment";
import { pubDroite, insert_stat_pub, compte_pubDroite, update_stat_pubDejaClique, update_stat_pub2 } from '../../api/pubs'

const Pub_droite = () => {

    const [pub, setPubDroite] = useState(0)
    const [stats_pub_d, setStatsPubDroite] = useState()
    const [vus, setVus] = useState("")
    const [vus_total, setVusTotal] = useState("")

    useEffect(() => {
        getPubDroite()
    }, [])

    const getPubDroite = () => {
        pubDroite()  // Sélectionne la pub de droite qui est en ligne
            .then((res) => {
                if (res !== undefined) {
                    //console.log("je veux les stats de la pub : ", res.id)
                    setPubDroite(res)
                    compte_pubDroite(res.id) // Récupère le nombre de vues du jour
                        .then((res) => {
                            setStatsPubDroite(res)
                            if (res === undefined) {
                                //console.log("// pas d'entrée aujourd'hui, met les vus à 0")
                                // pas d'entrée aujourd'hui, met les vus à 0
                                setVus(0)
                                setVusTotal(0)
                            }
                            else {
                                //console.log(" // déja une entrée aujourd'hui, on compte les nombres de vus")
                                // déja une entrée aujourd'hui, on compte les nombres de vus
                                setVus(res[0].vus)
                                setVusTotal(res[0].vus_total)
                            }
                        })
                        .catch(() => { 
                             //console.log("Pas d'entrée") 
                        })
                }
            })
            .catch(err => console.log(err))
    }

    //console.log("vus ", vus)
    //console.log("vus_total ", vus_total)
    const addStat = (infos_pub) => {

        const store_pub_droite = {
            id_pub: pub.id,
            jour: moment().format("YYYY-MM-DD")
        }

        //console.log("stats_pub_d ", stats_pub_d.length)
        if (stats_pub_d.length === 0) {
            //console.log("// pas d'entrée aujourd'hui, on crée une entrée pour ce jour")
            // pas d'entrée aujourd'hui, on crée une entrée pour ce jour
            insert_stat_pub(infos_pub)
            getPubDroite()
        }
        else {
            // déjà une entrée créée ce jour
            // on vérifie si l'user a déjà cliqué sur cette pub aujourd'hui
            //console.log("//on vérifie si l'user a déjà cliqué sur cette pub aujourd'hui")
            const checkLocalStorage_pud_d = JSON.parse(localStorage.getItem('pub_droite'));

            if (checkLocalStorage_pud_d) {
                if (checkLocalStorage_pud_d.jour === moment().format("YYYY-MM-DD")) {
                    // Déjà cliqué aujourd'hui !!!!
                    //console.log("// Déjà cliqué aujourd'hui !!!!")
                    const update1 = {
                        id_pub: infos_pub.id,
                        vus_total: vus_total
                    }
                    update_stat_pubDejaClique(update1)
                }
                else {
                    // Pas encore cliqué ce jour.......
                    //console.log("// Dans le local storage mais n'a Pas encore cliqué ce jour.......")
                    const update2 = {
                        id_pub: infos_pub.id,
                        vus: vus
                    }
                    update_stat_pub2(update2)
                }
            }
            else {
                // Rien dans le local storage
                //console.log(" // Rien dans le local storage")
                const update2 = {
                    id_pub: infos_pub.id,
                    vus: vus
                }
                //console.log("update2,", update2)
                update_stat_pub2(update2)
            }
            // quoi qu'il en soit, on mets dans le local storage le fait qu'il ai cliqué sur la pub
            localStorage.setItem("pub_droite", JSON.stringify(store_pub_droite));
        }
    }

    //    console.log("Stats publicité qui s'affiche", stats_pub_d)

    const repertoire = "https://www.formation-continue-imagerie.fr/images/bup";

    return (
        <div>
            {pub !== 0 ? (
                <a target="_blank" rel="noopener noreferrer" href={pub.www}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        addStat(pub)
                    }}
                >
                    <img
                        className="pub"
                        src={`${repertoire}/${pub.repertoire}/${pub.image}`}
                        alt="zimage"
                    />
                </a>
            ) : <a target="_blank" rel="noopener noreferrer" href={window.location.href}
                onMouseDown={(e) => {
                    e.preventDefault()
                    addStat(pub)
                }}
            >
                <img
                    className="pub"
                    src={`${repertoire}/default/blank.gif`}
                    alt="zimage"
                />
            </a>
            }
        </div>
    );




}
export default Pub_droite

/*
                <a target="_blank" href={pub.www}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        addStat(pub)
                    }}
                >

*/