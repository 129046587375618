import React from 'react'
import { Helmet } from 'react-helmet-async';
//import { styles } from './styles'

const Mentions_legales = (props) => {
  let meta_title ="Mentions légales du site www.clairance-creatinine.fr"
  let meta_cannonical ="https://clairance-creatinine.fr/Mentions_legales"
  let meta_description ="Mentions légales du site www.clairance-creatinine.fr"

  return (
    <div className=''>
    <Helmet>
      <title>{meta_title}</title>
      <link rel="canonical" href={meta_cannonical} />
      <meta name='description' content={meta_description} />
    </Helmet>
      <div className="flex1">
        <div className="span850 clairance_mobi_d">
          <h3>Mentions légales</h3>
          <h3>Introduction</h3>
          <p>1-	Les présentes contiennent des informations importantes sur les conditions (ci-après « conditions ») d'accès et d'utilisation du présent site accessible à l'adresse www.clairance-creatinine.fr (ci-après « Site ») qui s’appliquent entre la société qui édite le Site et tout utilisateur du Site. </p>
          <p>2-	Ce Site est destiné exclusivement à des professionnels de santé avertis. Si vous n'êtes pas un professionnel de santé, nous vous remercions de bien vouloir quitter le Site qui ne vous est pas destiné. Ce Site ne peut en effet en aucun cas répondre aux questions d'ordre médical du public. Il n'a pas davantage vocation à remplacer la relation qui existe entre le patient et son professionnel de santé ou encore de se substituer à son avis médical. D'une manière générale, nous vous recommandons de solliciter systématiquement l'avis de votre médecin habituel avant toute consultation de sites Internet présentant un contenu médical.</p>
          <p>3-	En accédant au Site et à son contenu, ou encore en utilisant l'un ou l'autre des services proposés sur le Site, vous acceptez entièrement et sans réserve ces Conditions définies ci-dessous et vous déclarez être lié pour une durée indéterminée par ces Conditions. Elles incluent notamment diverses limitations et exclusions de responsabilité, ainsi qu'une clause attributive de compétence régissant le traitement de tous différends.</p>
          <p>4-	Les présentes sont susceptibles de toutes modifications à tout moment et sans préavis ; elles sont systématiquement portées à la connaissance de toute personne accédant au Site par un lien accessible sur toutes pages du Site. Il vous appartient ainsi de consulter régulièrement ces Conditions et leur mise a jour.</p>
          <p>5-	Dans le cas ou vous n'acceptez pas tout ou partie des présentes Conditions, il vous est demande de renoncer à tout usage du Site.</p>
          <h3>1	Présentation du Site</h3>
          <h4>1-1	Descriptif et objectifs du Site</h4><br />
          <p>Le Site est destiné exclusivement à un public de professionnels de santé avertis (ci-après « Utilisateur(s) »). Il est conçu à des fins pédagogiques et contient des informations (articles, illustrations, outils et autres ressources, etc.) d'ordre médical.</p>
          <h4>1-2	Origine du Site</h4><br />
          <p>Le Site est édité par la société Webdoz dont le siège social est 36 rue Adolphe Muguet 38120 Saint-Egrève, SIRET : 51747226200016 (désignée ci-après par l’ « Editeur »).</p>

          <p>Le Site est hébergé par 1&1 Internet - AG Brauerstr 48 76135 Karlsruhe - Allemagne .</p>


          <h4>1-3	Financement du Site</h4><br />
          <p>Le Site est financé par l'Editeur et par la publicité. Le capital de l'Editeur est détenu par des personnes morales sans lien direct ou indirect avec l'industrie pharmaceutique.</p>


          <h3>2	Accès au site</h3>

          <h4>2-1	Libre accès et inscription</h4><br />
          <p>Le Site est gratuit et en libre accès. </p>

          <h4>2-2	Mise à jour et interruption du Site</h4><br />
          <p>L'Editeur peut, à tout moment, modifier ou supprimer des informations mises à disposition sur ce Site. Il se réserve la possibilité d'interrompre, de suspendre momentanément ou définitivement ou de modifier l'accès à tout ou partie du Site, afin d'en assurer la maintenance, ou pour toute autre raison, sans que cette interruption n'ouvre droit à aucune obligation ni indemnisation.</p>

          <h3>3	Données personnelles et confidentialité</h3>

          <h4>3-1	Confidentialité</h4><br />
          <p>Les informations personnelles concernant les visiteurs de notre site, y compris leur identité, sont confidentielles. L'Editeur s'engage à respecter les conditions légales de confidentialité applicables en France et à ne pas divulguer ces informations à des tiers.</p>

          <h4>3-2	Déclaration CNIL</h4><br />
          <p>L'Editeur s'engage à ce que la collecte et le traitement d'informations personnelles, effectués au sein du présent Site, respecte la loi n°78-17 du 6 janvier 1978.
            A cet égard, l'Editeur précise que, selon la loi du 6 janvier 1978 modifiée en 2004, ce fichier est dispensé de déclaration à la CNIL puisque le Site se conforme aux règles énoncées par la Délibération n°2006-138 du 09/05/2006 décidant de la dispense de déclaration des traitements constitués à des fins d'information ou de communication externe.</p>

          <h4>3-3	Collecte de données personnelles</h4><br />
          <p>Le Site collecte des informations nominatives par l’intermédiaire, notamment, de formulaires et de bulletins d’inscription.</p>
          <p>Conformément aux articles 38, 39 et 40 de la loi « Informatique et Libertés » n° 78-17 du 6 janvier 1978 modifiée en août 2004, vous disposez d’un droit d’accès, d’opposition, de suppression et de modifications des données qui vous concernent, et/ou d’opposition à leur cession à des tiers.</p>
          <p>Pour l’exercer, envoyer un Email à l'adresse cedric@clairance-creatinine.fr.</p>
          <h4>3-4	Droits de l'Utilisateur</h4><br />
          <p>L'Editeur s'engage à respecter la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.</p>
          <p>Conformément aux articles 39 et suivants de ladite loi, toute personne peut ainsi obtenir communication et, le cas échéant, rectification ou suppression, d'opposition des informations la concernant, en envoyant un Email à l'adresse cedric@clairance-creatinine.fr.</p>

          <h4>3-5	Statistiques</h4><br />
          <p>L'Editeur souscrit à des services de sociétés tierces afin d'établir des statistiques des visites et de l'utilisation du Site.</p>
          <p>Ces statistiques sont strictement anonymes et ne donnent lieu à aucun traitement de données personnelles.</p>
          <p>Le Site utilise Google analytics pour analyser l'audience du site et améliorer son contenu. Pour plus d'information sur les règles de confidentialité relatives à l'utilisation de Google Analytics, cliquez ici&nbsp;:
            <a href="https://www.google.com/intl/fr/analytics/privacyoverview.html">https://www.google.com/intl/fr/analytics/privacyoverview.html</a></p>

          <h3>4	Avertissement aux utilisateurs</h3><br />

          <h4>4-1	Qualité des informations fournies</h4><br />
          <p>L'Editeur s'efforce d'apporter le plus grand soin à la qualité des informations fournies sur ce Site et à leur mise à jour régulière. Il se peut toutefois que le Site présente des informations erronées ou inexactes malgré la volonté de l'Editeur de délivrer des informations exactes. L'Editeur attire particulièrement l'attention sur le fait que les informations publiées sur le Site ne restent ainsi délivrées qu'à titre d'indicatif et dans un but éducatif sans garantie expresse ou tacite d'aucune sorte, à l'usage exclusif des professionnels de santé dûment habilités à pratiquer en France ou dans leurs pays d'origine.</p>
          <p>Ces informations ne sauraient en aucun cas se substituer à l'avis de professionnels de santé ou encore être considérées ou interprétées comme un conseil ou une recommandation de quelque nature que ce soit.</p>
          <p>L'Utilisateur est le seul responsable de l'usage des informations mises à sa disposition. Il est invité à les utiliser avec discernement et à recourir à son propre jugement professionnel afin de les évaluer. L'Editeur conseille en outre la consultation systématique d'autres sources d'informations.</p>

          <h4>4-2	Exclusion de responsabilité et de garantie de l'Editeur</h4><br />
          <p>L'utilisation du Site et de ces informations relève ainsi de la seule et entière responsabilité des Utilisateurs.</p>
          <p>Par conséquent, la responsabilité de l'Editeur ou de ses partenaires ne saurait être engagée à raison de tout préjudice ou dommage, direct ou indirect, de quelque nature que ce soit, résultant de l'accès, l'utilisation, même partielle, l'interprétation des informations de ce Site.</p>

          <h4>4-3	Responsabilité et garantie de l'Utilisateur du Site</h4><br />
          <p>L'Utilisateur garantit et indemnise l'Editeur ou ses partenaires contre toute action ou réclamation d'un tiers en raison de l'usage du Site par l'Utilisateur ou encore de toutes conséquences dommageables liées directement ou indirectement à l'usage qu'il fait du Site.</p>
          <p>L'Utilisateur prend ainsi à sa charge l'intégralité des dommages et intérêts auxquels l'Editeur ou ses partenaires pourraient être condamnés ainsi que les frais de justice et les honoraires exposés.</p>
          <p>L'Utilisateur est seul responsable de vérifier si le Contenu du Site est conforme à la législation du pays à partir duquel il accède au Site. Il s'interdit ainsi toute utilisation du Site contraire aux dispositions légales, règlementaires ou déontologiques applicables au regard du droit du pays à partir duquel il accède au Site. La responsabilité de l'Editeur ne saurait ainsi en aucun cas être engagée en cas de non-respect de la législation du pays où le Site est utilisé.</p>

          <h4>4-4	Liens hypertextes</h4><br />
          <p>Ce Site est susceptible de contenir des liens hypertextes vers d'autres sites Internet gérés par des tiers. L'Editeur ne peut toutefois aucunement procéder à la vérification régulière de la qualité de ces sites liés. L'Editeur ne saurait ainsi être tenu responsable ni du contenu de ces sites, ni des services ou services proposés sur ces sites. </p>

          <h4>4-5	Publicité</h4><br />
          <p>L'Editeur est susceptible de souscrire à des services proposés par des sociétés tierces consistant à leur permettre d'insérer directement et automatiquement des publicités dans des emplacements du Site. Le contenu de ces publicités reste toutefois sous le contrôle de l'Editeur qui peut les supprimer du Site.</p>
          <p>Les annonceurs n'ont aucun droit de regard sur le contenu éditorial du site.</p>

          <h3>5	Propriété du site et de son contenu</h3>

          <h4>5-1	Protection du contenu du Site</h4><br />
          <p>L'intégralité des droits de propriété intellectuelle sur le Site et son contenu (ci-après « Contenu ») constitué notamment de textes, bases de données,  logos, images, dessins, graphiques, séquences animées, sons, vidéos sont la propriété de l'Editeur ou de tiers ayant autorisé l'Editeur à les exploiter.</p>
          <p>L'ensemble de ce Contenu est ainsi protégé par les législations françaises et internationales notamment et le cas échéant sur le droit d'auteur, le droit des dessins et modèles, le droit des marques, le droit des bases de données.</p>

          <h4>5-2	Usage du Site</h4><br />
          <p>Ce Site est destiné exclusivement à l'usage personnel et privé de l'Utilisateur. L'Editeur ne concède ainsi à l'Utilisateur qu'une autorisation de visualisation du Site.</p>
          <p>Tout autre usage (notamment toute reproduction, représentation, modification, adaptation, distribution à des fins commerciales, lucratives ou non) est strictement interdit sauf accord exprès préalable et écrit de l'Editeur.</p>
          <p>L'Editeur étant producteur et propriétaire de tout ou partie des bases de données présentes ou utilisées sur le Site, il est en outre strictement interdit, par exemple, d'extraire et d'utiliser tout ou partie du contenu des bases de données figurant sur le Site.</p>

          <h3>6.	Disposition diverses</h3>

          <h4>6-1	Réclamation</h4><br />
          <p>Toute réclamation, tout signalement d'un quelconque abus ou d'une violation de droits de propriété intellectuelle doivent être formulés par écrit au siège de l'Editeur.</p>
          <p>En cas de violation de droits de propriété intellectuelle, les informations suivantes doivent être communiquées :</p>
          <p>- Identification, coordonnées et signature du propriétaire des droits déclarés comme ayant été violés ;</p>
          <p>- Mandat le cas échéant du représentant dudit propriétaire aux fins d'établissement de l'avis de violation des droits ;</p>
          <p>- Description précise des éléments ne respectant pas les droits dudit propriétaire dont il est demandé la suppression du Site ;</p>
          <p>- Déclaration sur l'honneur confirmant l'exactitude des informations transmises à l'Editeur.</p>

          <h4>6-2	Manquement de l'Utilisateur</h4><br />
          <p>Le fait pour l'Editeur de ne pas se prévaloir d'un manquement de l'Utilisateur de l'une quelconque des obligations légales ou des obligations visées dans les présentes, ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause, et à se prévaloir de ce manquement ultérieurement.</p>
          <p>Si l'une des clauses des présentes Conditions est nulle au regard d'une loi ou de toute autre règle de droit en vigueur, elle sera réputée non écrite, sans que cela entraîne la nullité des Conditions dans son ensemble.</p>

          <h4>6-3	Loi applicable et compétence</h4><br />
          <p>Le Site a été conçu en France. Les Conditions sont soumises à l'application de la loi française.</p>
          <p>En cas de contestation portant sur l'application ou l'interprétation des Conditions ou plus largement sur l'utilisation du Site par toute personne physique ou morale, il est expressément convenu que les tribunaux français seront seuls compétents, même en cas de pluralité de défendeurs ou d'appel en garantie.</p>

          <h4>6-4 Traduction</h4><br />
          <p>La traduction en anglais ou en toutes autres langues des Conditions est fournie uniquement à titre indicatif. </p>
          <p>En cas de contradiction entre la version anglaise et la version française, la version française est la seule à faire foi et la seule qui engage les deux parties et gouverne la relation avec l’Editeur.</p>

          <h3>Date et lieu de dernière mise à jour spécifique des conditions d'utilisation</h3>
          <p>Le jeudi 27 mai 2016 à Grenoble par Cédric Mendoza, Webmaster du site www.clairance-creatinine.fr</p>

        </div>

      </div>
    </div>
  )

}
export default Mentions_legales