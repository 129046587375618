import { useState, useEffect } from "react";

const InstallButton = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallButton, setShowInstallButton] = useState(false);
    

    useEffect(() => {
        console.log('Étape 1: Dans useEffect');
      
        const handleBeforeInstallPrompt = (e) => {
          console.log('Étape 2: beforeinstallprompt event triggered');
          e.preventDefault();
          setDeferredPrompt(e);
          setShowInstallButton(true); // Afficher le bouton d'installation
          console.log('Étape 3: deferredPrompt set, showInstallButton set to true');
        };
      
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      
        return () => {
          console.log('Étape 4: Clean up event listener');
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      }, []);
      
      const handleInstallClick = () => {
        console.log('Étape 5: handleInstallClick triggered');
        if (deferredPrompt) {
          console.log('Étape 6: deferredPrompt exists, prompting install');
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('Étape 7: Installation acceptée');
            } else {
              console.log('Étape 8: Installation refusée');
            }
            setDeferredPrompt(null);
            setShowInstallButton(false); // Masquer le bouton après l'installation
            console.log('Étape 9: deferredPrompt reset, showInstallButton set to false');
          });
        } else {
          console.log('Étape 10: deferredPrompt is null, no prompt available');
        }
      };
      
    
    // Affichage du bouton et du texte
    return (
      <>
        {showInstallButton ? (
          <button onClick={handleInstallClick} className="btn-wpa">
            <img src="512.png" alt="logo" className="btn-logo" />
            Installer l'application
            <span onClick={() => setShowInstallButton(false)}>X</span>
          </button>
        ) : null}
        <p>deferredPrompt : {deferredPrompt ? 'Available' : 'Not available'}</p>
        <p>showInstallButton : {showInstallButton.toString()}</p>
      </>
    );
    
};

export default InstallButton;
